import { ThirdPartyAuthorizationService } from "../third-party-authorization/third-party-authorization.service";
import { v4 as uuid } from 'uuid';
import {Browser} from "@capacitor/browser";
import {App} from "@capacitor/app";
const CryptoJS = require("crypto-js");

export class AppleLoginService {
    static async login() {
        console.log('🔄 Starte Apple Login via OAuth...');

        try {
            const oauthEndpoint = await ThirdPartyAuthorizationService.getAuthorizeEndpoint('apple');
            console.log('✅ OAuth Endpoint:', oauthEndpoint);

            // Extrahiere Parameter aus `oauthEndpoint`
            const url = new URL(oauthEndpoint);
            const { rawNonce, hashedNonce } = await this.generateNonce();

            // Hänge die Nonce an die URL (falls Apple sie benötigt)
            url.searchParams.set("nonce", hashedNonce);

            console.log('🚀 Weiterleitung zu Apple Login:', url.toString());

            // Event-Listener für Deep-Linking setzen



            // Öffne Apple Sign-In in Safari oder dem Standardbrowser
            await Browser.open({ url: url.toString() });

        } catch (error) {
            console.error('❌ Fehler beim Apple Login:', error.stack || error.message || JSON.stringify(error));
        }
    }

    static async generateNonce() {
        try {
            const rawNonce = uuid();
            console.log('📌 Generierte rawNonce:', rawNonce);

            const hashedNonce = CryptoJS.SHA256(rawNonce).toString(CryptoJS.enc.Base64)
                .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

            console.log('📌 Base64-URL-kodierte Nonce:', hashedNonce);
            return { rawNonce, hashedNonce };
        } catch (error) {
            console.error('❌ Fehler in generateNonce():', error);
            throw new Error(`generateNonce() Fehler: ${error.message || JSON.stringify(error)}`);
        }
    }
}
